<template>
  <div class="page1">
    <div class="content">
      <div style="position: absolute;top: 40px;left: 54px">
        <img style="width: 135px;height: 42px" src="../../../assets/images/pageLogo.png">
      </div>
      <div class="myCard">
        <div class="form">
          <span class="title">智慧菌草云平台</span>
          <span class="title1">欢迎来到智慧菌草云平台，请登录！</span>
          <div v-if="display" class="errorMessage">
            <div class="content">
              <div class="ico"><img style="width:20px;height: 20px" src="../../../assets/images/close.png"></div>
              <div class="text">{{displayMessage}}</div>
            </div>
            <i @click="display=false" style="font-size: 7px;cursor:pointer;" class="pi pi-times"></i>
          </div>
          <div class="p-field">
            <div class="p-input-icon-left p-float-label" style="width:100%;margin-top: 20px;">
              <i class="pi pi-mobile field-icon"/>
              <InputText type="text" v-model="telephone" :class="{'p-invalid':telephoneAlert}" style="width:100%;height:48px;" placeholder="请输入手机号"/>
            </div>
            <small v-if="telephoneAlert" class="p-error">{{telephoneAlert}}</small>
          </div>
          <div class="p-field">
            <div class="p-grid" style="margin:30px 0 8px 0">
              <div class="p-input-icon-left p-float-label p-col" style="padding:0 7px 0 0">
                <i class="pi pi-lock field-icon"/>
                <InputText class="p-col" type="text" v-model="code" :class="{'p-invalid':codeAlert}" style="width:100%;height:48px;" placeholder="请输入验证码"/>
              </div>
              <Button @click="sendSms()" style="border:1px solid #CED4DA;" class="p-button-outlined p-button-secondary">
                发送验证码
              </Button>
<!--              <img :src="codeUrl" @click="refresh()" style="width:30%;cursor: pointer;height: 48px;padding:7px"/>-->
            </div>
            <small v-if="codeAlert" class="p-error">{{codeAlert}}</small>
            <small v-if="sendSuccess" class="p-error">{{sendSuccess}}</small>
          </div>
          <router-link style="color: #12A25D;margin-top:8px;" to="/login">我要登录</router-link>
          <Button @click="handleSubmit" class="group4 flex-col loginbtn">
            <span class="info2" style="width:100px;">发送密码</span>
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      telephone : null,
      code : null,
      telephoneAlert : null,
      codeAlert : null,
      sendSuccess: null,
      display: false,
      displayMessage: null,
      dialogContent: null,
      codeUrl: this.$serverUrl+'/user/getCode?rand='+Math.random()+"&token=" + this.$getToken()
    };
  },
  methods: {
    sendSms(){
      this.display = false;
      if(!this.telephone || !/^1\d{10}$/.test(this.telephone)){
        this.telephoneAlert = "手机号码为空或格式错误！";
        return
      }
      this.telephoneAlert = false;
      this.$http("/user/sendSms", "get", {telephone: this.telephone},
          ()=>{
            this.sendSuccess = "验证码发送成功，请注意查收";
          },
          (resp)=>{
            this.display = true;
            this.displayMessage = resp.message;
            this.sendSuccess = null;
          }
      )
    },
    refresh(){
      this.codeUrl = this.$serverUrl+'/user/getCode?rand='+Math.random()+"&token=" + this.$getToken()
    },
    handleSubmit() {
      let me = this;

      // 删除发送成功的消息
      this.sendSuccess = null;
      this.codeAlert = null;

      if(!this.telephone || !/^1\d{10}$/.test(this.telephone)){
        me.telephoneAlert = "手机号码为空或格式错误！";
        return
      }
      me.telephoneAlert = false;

      if(!this.code){
        me.codeAlert = "请输入验证码！";
        return
      }
      me.codeAlert = false;

      if(this.telephone && this.code){

        this.$http("/user/sendPassword","get",{
          "code" : this.code,
          "telephone" : this.telephone
        },function (){
          me.sendSuccess = "重置密码已发送到您的手机！";
        },function (response){
          if(response.code == "000001"){
            me.codeAlert = "验证码不正确！";
            me.refresh()
          }else if(response.code == "000002"){
            me.display = true;
            me.dialogContent = "您可能不是系统用户，请联系管理员！";
            me.refresh()
          }
        })
      }
    }
  }
};
</script>

<style scoped>
.errorMessage{
  height: 40px;
  width:100%;
  padding: 9px;
  display: flex;
  justify-content: space-between;
  background-color: #FFF1F0;
  border: 1px solid #FFCCC7;
  border-radius: 2px;
}
.errorMessage i{
  padding-top: 5px;
}
.errorMessage .content{
  display: flex;
}
.errorMessage .content .text{
  color: rgba(0,0,0,0.65);
}
.errorMessage .content .ico{
  margin-right: 5px;
}

.message{
  width: 183px;
  height: 24px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
}
.page1 {
  width: 100%;
  height: 900px;
  display: block;
  background: url(../../../assets/images/login_bg.jpg) no-repeat;
  background-size: 100%;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.myCard {
  width: 504px;
  height: 60%;
  margin: 0 auto;
  margin-top: 10%;
  background: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0px 2px 20px 0px rgba(0, 50, 49, 0.45);
  display: flex;
}

@media screen and (min-width: 1441px) {
  .page1 {
    width: 100%;
    height: 970px;
    display: block;
    background: url(../../../assets/images/login_bg.jpg) no-repeat ;
    background-size:cover;
  }
}

.background1 {
  width: 42.2%;
  height: 100%;
  background: url("../../../assets/images/login_left.png") no-repeat;
  background-size: cover;
  border-radius: 4px 0 0 4px;
  overflow: hidden;
}

.form {
  width: 504px;
  height: 100%;
  padding: 13.11% 8.2% 0 7.5%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.title {
  width: 100%;
  display: block;
  color: rgba(18, 162, 93, 1);
  font-size: 32px;
  font-family: PingFangSC-Semibold;
  line-height: 45px;
  text-align: center;
}

.title1 {
  width: 100%;
  color: rgba(0, 0, 0, 0.45);
  display: block;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  align-self: center;
  margin-top: 15px;
  margin-bottom: 20px;
}

.p-field {
  width: 100%;
  margin:0
}

.group4 {
  width: 100%;
  height: 48px;
  border-radius: 2px;
  background-color: rgba(53, 183, 117, 1);
  box-shadow: 0px 6px 20px -8px rgba(18, 162, 93, 1);
  align-self: center;
  margin-top: 40px;
  justify-content: center;
  align-items: center;
}

.p-button,.p-button:hover {
  background: #12A25D;
  border-color: #12A25D;
}

</style>
